var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full mb-base"},[(_vm.report && _vm.report.bulk)?_c('vx-card',{staticClass:"show-overflow",attrs:{"title-color":"primary","subtitle-color":"primary","content-color":"primary"}},[_c('template',{slot:"no-body"},[_c('div',{staticClass:"flex justify-between flex-row-reverse items-center p-4"},[_c('feather-icon',{staticClass:"p-3 inline-flex rounded-full",class:[`text-primary`, { 'mb-4': true }],style:({
                            background: `rgba(var(--vs-primary),.15)`,
                        }),attrs:{"icon":'BarChart2Icon'}}),_c('div',{staticClass:"truncate"},[_c('h2',{staticClass:"mb-1 font-bold text-primary"},[_vm._v(" Auto Send ")])])],1),(_vm.report)?_c('div',{staticClass:"pb-4"},[_c('vue-apex-charts',{attrs:{"height":"120px","options":_vm.options,"series":_vm.seriesBulk}})],1):_vm._e()])],2):_vm._e()],1),_c('div',{staticClass:"vx-col w-full mb-base"},[(_vm.report && _vm.report.bulk)?_c('vx-card',{staticClass:"show-overflow",attrs:{"title-color":"primary","subtitle-color":"primary","content-color":"primary"}},[_c('template',{slot:"no-body"},[_c('div',{staticClass:"flex justify-between flex-row-reverse items-center p-4"},[_c('feather-icon',{staticClass:"p-3 inline-flex rounded-full",class:[`text-primary`, { 'mb-4': true }],style:({
                            background: `rgba(var(--vs-primary),.15)`,
                        }),attrs:{"icon":'BarChart2Icon'}}),_c('div',{staticClass:"truncate"},[_c('h2',{staticClass:"mb-1 font-bold text-primary"},[_vm._v(" Quick Send ")])])],1),(_vm.report)?_c('div',{staticClass:"pb-4"},[_c('vue-apex-charts',{attrs:{"height":"120px","options":_vm.options,"series":_vm.seriesQuick}})],1):_vm._e()])],2):_vm._e()],1),_c('div',{staticClass:"vx-col w-full mb-base"},[(_vm.report && _vm.report.bulk)?_c('vx-card',{staticClass:"show-overflow",attrs:{"title-color":"primary","subtitle-color":"primary","content-color":"primary"}},[_c('template',{slot:"no-body"},[_c('div',{staticClass:"flex justify-between flex-row-reverse items-center p-4"},[_c('feather-icon',{staticClass:"p-3 inline-flex rounded-full",class:[`text-primary`, { 'mb-4': true }],style:({
                            background: `rgba(var(--vs-primary),.15)`,
                        }),attrs:{"icon":'BarChart2Icon'}}),_c('div',{staticClass:"truncate"},[_c('h2',{staticClass:"mb-1 font-bold text-primary"},[_vm._v(" Handover ")])])],1),(_vm.report)?_c('div',{staticClass:"pb-4"},[_c('vue-apex-charts',{attrs:{"height":"120px","options":_vm.options,"series":_vm.seriesHandover}})],1):_vm._e()])],2):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }