<template>
    <div>
        <Last28DaysCard :report="report" />
    </div>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/textFormat'

import Last28DaysCard from '@/views/main/Reports/SendLogs/Cards/Last28Days'

export default {
    name: 'ExecutorsReport',
    components: {
        Last28DaysCard,
    },
    mixins: [textFormat],
    mounted() {
        this.getReport(this.initialDays)
    },
    watch: {
        range: {
            immediate: true,
            deep: true,
            handler(valueNew, valueOld) {
                console.log('range changed', valueNew)
                if (valueNew) this.getReport(null, valueNew.start, valueNew.end)
            },
        },
    },
    computed: {
        subtitle() {
            return 'Last 28 Days'
        },
    },
    data() {
        return {
            report: null,
            range: null,
            initialDays: 28,
        }
    },
    methods: {
        getReport(days = null, start = null, end = null) {
            let params = {
                days: days,
                start: start,
                end: end,
            }
            http.get('broker_overview', {
                params: params,
            })
                .then((response) => {
                    this.report = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        setDuration(value) {
            this.range = null
            if (value === '365') this.getReport(365)
            if (value === '182') this.getReport(182)
            if (value === '28') this.getReport(28)
            if (value === '14') this.getReport(14)
            if (value === '7') this.getReport(7)
        },
    },
}
</script>

<style>
.over {
    overflow: visible;
}
</style>
