<template>
    <div class="vx-row">
        <div class="vx-col w-full mb-base">
            <vx-card
                v-if="report && report.bulk"
                title-color="primary"
                subtitle-color="primary"
                content-color="primary"
                class="show-overflow"
            >
                <template slot="no-body">
                    <div
                        class="flex justify-between flex-row-reverse items-center p-4"
                    >
                        <feather-icon
                            :icon="'BarChart2Icon'"
                            class="p-3 inline-flex rounded-full"
                            :class="[`text-primary`, { 'mb-4': true }]"
                            :style="{
                                background: `rgba(var(--vs-primary),.15)`,
                            }"
                        ></feather-icon>

                        <div class="truncate">
                            <h2 class="mb-1 font-bold text-primary">
                                Auto Send
                            </h2>
                        </div>
                    </div>
                    <div class="pb-4" v-if="report">
                        <vue-apex-charts
                            height="120px"
                            :options="options"
                            :series="seriesBulk"
                        ></vue-apex-charts>
                    </div>
                </template>
            </vx-card>
        </div>
        <div class="vx-col w-full mb-base">
            <vx-card
                v-if="report && report.bulk"
                title-color="primary"
                subtitle-color="primary"
                content-color="primary"
                class="show-overflow"
            >
                <template slot="no-body">
                    <div
                        class="flex justify-between flex-row-reverse items-center p-4"
                    >
                        <feather-icon
                            :icon="'BarChart2Icon'"
                            class="p-3 inline-flex rounded-full"
                            :class="[`text-primary`, { 'mb-4': true }]"
                            :style="{
                                background: `rgba(var(--vs-primary),.15)`,
                            }"
                        ></feather-icon>

                        <div class="truncate">
                            <h2 class="mb-1 font-bold text-primary">
                                Quick Send
                            </h2>
                        </div>
                    </div>
                    <div class="pb-4" v-if="report">
                        <vue-apex-charts
                            height="120px"
                            :options="options"
                            :series="seriesQuick"
                        ></vue-apex-charts>
                    </div>
                </template>
            </vx-card>
        </div>
        <div class="vx-col w-full mb-base">
            <vx-card
                v-if="report && report.bulk"
                title-color="primary"
                subtitle-color="primary"
                content-color="primary"
                class="show-overflow"
            >
                <template slot="no-body">
                    <div
                        class="flex justify-between flex-row-reverse items-center p-4"
                    >
                        <feather-icon
                            :icon="'BarChart2Icon'"
                            class="p-3 inline-flex rounded-full"
                            :class="[`text-primary`, { 'mb-4': true }]"
                            :style="{
                                background: `rgba(var(--vs-primary),.15)`,
                            }"
                        ></feather-icon>

                        <div class="truncate">
                            <h2 class="mb-1 font-bold text-primary">
                                Handover
                            </h2>
                        </div>
                    </div>
                    <div class="pb-4" v-if="report">
                        <vue-apex-charts
                            height="120px"
                            :options="options"
                            :series="seriesHandover"
                        ></vue-apex-charts>
                    </div>
                </template>
            </vx-card>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'Last28DaysCard',
    props: ['report'],
    mixins: [textFormat],
    components: {
        VueApexCharts,
    },
    data() {
        return {}
    },
    computed: {
        options() {
            return {
                chart: {
                    stacked: true,
                    sparkline: {
                        enabled: false,
                    },
                    lines: {
                        show: false,
                    },
                    toolbar: {
                        show: false,
                        tools: {
                            download: false,
                        },
                    },
                },
                legend: {
                    show: false,
                },
                toolbar: {
                    show: false,
                },
                dropShadow: {
                    enabled: true,
                    top: 5,
                    left: 0,
                    blue: 4,
                    opacity: 0.1,
                },
                grid: {
                    show: true,
                    padding: {
                        left: 0,
                        right: 0,
                    },
                    xaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false,
                        },
                    },
                },
                states: {
                    hover: {
                        filter: 'none',
                    },
                },
                plotOptions: {
                    bar: {
                        stacked: true,
                        horizontal: false,
                        columnWidth: '80%',
                        dataLabels: {
                            position: 'bottom',
                        },
                    },
                },
                stroke: {
                    width: 2,
                    colors: ['#fff'],
                },
                fill: {
                    opacity: 1,
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: true,
                    },
                },
                colors: ['#CED4DC', '#e56781', '#345a7a', '#4f9b7e'],
                xaxis: {
                    axisBorder: {
                        show: false,
                        color: '#78909C',
                        offsetX: 0,
                        offsetY: 0,
                    },
                    axisTicks: {
                        show: false,
                        borderType: 'solid',
                        color: '#78909C',
                        width: 6,
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false,
                        color: '#78909C',
                        offsetX: 0,
                        offsetY: 0,
                    },
                    axisTicks: {
                        show: false,
                        borderType: 'solid',
                        color: '#78909C',
                        width: 6,
                        offsetX: 0,
                        offsetY: 0,
                    },
                    labels: {
                        show: false,
                    },
                },
            }
        },
        seriesBulk() {
            return [
                // {
                //     name: 'Not Engaged',
                //     type: 'bar',
                //     data: this.report.bulk.map((value) => {
                //         return {
                //             x: value.label,
                //             y: value.not_engaged,
                //         }
                //     }),
                // },
                {
                    name: 'Clicked',
                    type: 'bar',
                    data: this.report.bulk.map((value) => {
                        return {
                            x: value.label,
                            y: value.clicked,
                        }
                    }),
                },
                {
                    name: 'In Progress',
                    type: 'bar',
                    data: this.report.bulk.map((value) => {
                        return {
                            x: value.label,
                            y: value.used,
                        }
                    }),
                },
                {
                    name: 'Completed',
                    type: 'bar',
                    data: this.report.bulk.map((value) => {
                        return {
                            x: value.label,
                            y: value.completed,
                        }
                    }),
                },
            ]
        },
        seriesQuick() {
            return [
                // {
                //     name: 'Not Engaged',
                //     type: 'bar',
                //     data: this.report.quick.map((value) => {
                //         return {
                //             x: value.label,
                //             y: value.not_engaged,
                //         }
                //     }),
                // },
                {
                    name: 'Clicked',
                    type: 'bar',
                    data: this.report.quick.map((value) => {
                        return {
                            x: value.label,
                            y: value.clicked,
                        }
                    }),
                },
                {
                    name: 'In Progress',
                    type: 'bar',
                    data: this.report.quick.map((value) => {
                        return {
                            x: value.label,
                            y: value.used,
                        }
                    }),
                },
                {
                    name: 'Completed',
                    type: 'bar',
                    data: this.report.quick.map((value) => {
                        return {
                            x: value.label,
                            y: value.completed,
                        }
                    }),
                },
            ]
        },
        seriesHandover() {
            return [
                // {
                //     name: 'Not Engaged',
                //     type: 'bar',
                //     data: this.report.handover.map((value) => {
                //         return {
                //             x: value.label,
                //             y: value.not_engaged,
                //         }
                //     }),
                // },
                {
                    name: 'Clicked',
                    type: 'bar',
                    data: this.report.handover.map((value) => {
                        return {
                            x: value.label,
                            y: value.clicked,
                        }
                    }),
                },
                {
                    name: 'In Progress',
                    type: 'bar',
                    data: this.report.handover.map((value) => {
                        return {
                            x: value.label,
                            y: value.used,
                        }
                    }),
                },
                {
                    name: 'Completed',
                    type: 'bar',
                    data: this.report.handover.map((value) => {
                        return {
                            x: value.label,
                            y: value.completed,
                        }
                    }),
                },
            ]
        },
    },
}
</script>
